import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'sl-empty-panel',
	templateUrl: './empty-panel.component.html',
	styleUrl: './empty-panel.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule
	]
})
export class EmptyPanelComponent { }
